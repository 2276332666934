//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { checkBreakpoint } from "~/helpers";

export default {
    name: "BowSlider",

    props: {
        autoplay: {
            default: false,
            type: Boolean,
        },
        breakpoints: {
            default: () => ({
                768: {
                    slidesPerView: 2.5,
                },
                1024: {
                    slidesPerView: 2.75,
                },
                1536: {
                    slidesPerView: 4,
                },
            }),
            type: Object,
        },
        breakpointsThumb: {
            default: null,
            type: Object,
        },
        centeredSlides: {
            default: false,
            type: Boolean,
        },
        centeredSlidesThumb: {
            default: false,
            type: Boolean,
        },
        effect: {
            default: "slide",
            type: String,
            validation: (val) =>
                [
                    "slide",
                    "fade",
                    "cube",
                    "coverflow",
                    "flip",
                    "creative",
                    "cards",
                ].includes(val),
        },
        fadeOutSlide: {
            default: false,
            type: Boolean,
        },
        loop: {
            default: false,
            type: Boolean,
        },
        navigation: {
            default: true,
            type: Boolean,
        },
        navigationThumb: {
            default: true,
            type: Boolean,
        },
        pagination: {
            default: true,
            type: Boolean,
        },
        paginationThumb: {
            default: true,
            type: Boolean,
        },
        preventClicks: {
            default: true,
            type: Boolean,
        },
        slidesFullSlider: {
            default: null,
            type: Array,
        },
        slidesPerView: {
            default: 1.5,
            type: [Number, String],
        },
        slidesPerViewThumb: {
            default: 1.5,
            type: Number,
        },
        spaceBetween: {
            default: 16,
            type: Number,
        },
        thumb: {
            default: false,
            type: Boolean,
        },
    },

    data: () => ({
        slider: null,
        swiperThumb: null,
    }),

    computed: {
        ...mapState({
            fullSliderData: ({ ui }) => ui.fullSliderData,
            fullSliderThumb: ({ ui }) => ui.fullSliderThumb,
            showFullSlider: ({ ui }) => ui.showFullSlider,
        }),

        ...mapGetters([
            "ui/GET_FULL_SLIDER_DATA",
            "ui/GET_FULL_SLIDER_THUMB",
            "ui/GET_SHOW_FULL_SLIDER",
        ]),
    },

    watch: {
        fullSliderThumb(val, oldVal) {
            if (this.swiperThumb) {
                this.swiperThumb.slideToLoop(val, 0, true);
            }
        },
    },

    created() {
        if (this.thumb === true) {
            this.$store.dispatch("ui/UPDATE_FULL_SLIDER_DATA", {
                slides: this.slidesFullSlider,
                options: {
                    breakpoints: null,
                    centeredSlides: this.centeredSlides,
                    loop: this.loop,
                    navigation: this.navigation,
                    pagination: this.pagination,
                    slidesPerView: this.slidesPerView,
                    spaceBetween: this.spaceBetween,
                },
            });
        }
    },

    mounted() {
        if (this.thumb === true) {
            this.swiperThumb = new this.$swiper(
                this.$refs["slider-container-thumb"],
                {
                    allowSlideNext: true,
                    allowSlidePrev: true,
                    breakpoints: this.breakpointsThumb,
                    centeredSlides: this.centeredSlidesThumb,
                    loop: this.loop,
                    slidesPerView: this.slidesPerViewThumb,
                    spaceBetween: this.spaceBetween,
                    watchSlidesProgress: true,
                }
            );

            this.$store.dispatch(
                "ui/UPDATE_FULL_SLIDER_THUMB",
                this.swiperThumb.realIndex
            );

            this.swiperThumb.on("realIndexChange", (swiper) => {
                this.$store.dispatch(
                    "ui/UPDATE_FULL_SLIDER_THUMB",
                    swiper.realIndex
                );
                // console.log(swiper.realIndex);
            });

            [...this.$el.querySelectorAll(".swiper-slide")].forEach((item) => {
                item.addEventListener("click", (e) => {
                    if (
                        e.target.classList.contains("swiper-slide-active") ||
                        e.target.closest(".swiper-slide-active")
                    ) {
                        this.$store.dispatch(
                            "ui/UPDATE_SHOW_FULL_SLIDER",
                            true
                        );
                    }
                });
            });

            // this.swiperThumb.slides.forEach(item => {
            //   item.addEventListener('click', () => {
            //     // this.swiperThumb.slideToLoop( item.getAttribute('data-swiper-slide-index'), 0, true );
            //     this.$store.dispatch('ui/UPDATE_SHOW_FULL_SLIDER', true);
            //   })
            // });
        } else {
            this.slider = new this.$swiper(this.$refs["slider-container"], {
                allowSlideNext: true,
                allowSlidePrev: true,
                autoplay: this.autoplay,
                breakpoints: this.breakpoints,
                centeredSlides: this.centeredSlides,
                effect: this.effect,
                loop: this.loop,
                navigation:
                    this.navigation !== false
                        ? {
                              prevEl: this.$refs["slider-prev"],
                              nextEl: this.$refs["slider-next"],
                          }
                        : false,
                pagination:
                    this.pagination !== false
                        ? {
                              el: this.$refs["slider-pagination"],
                              type: "bullets",
                          }
                        : false,
                preventClicks: this.preventClicks,
                slidesPerView: this.slidesPerView,
                spaceBetween: this.spaceBetween,
            });
        }

        if (this.thumb === false && this.fadeOutSlide === true) {
            if (checkBreakpoint.up("lg")) {
                this.$anime({
                    targets: this.slider.slides.filter(
                        (el, idx) => idx < this.slider.activeIndex
                    ),
                    opacity: 0,
                    duration: 150,
                    easing: "easeInOutSine",
                });

                [
                    ...this.slider.slides.filter(
                        (el, idx) => idx < this.slider.activeIndex
                    ),
                ].forEach((el) => {
                    el.classList.add("pointer-events-none");
                });

                this.$anime({
                    targets: this.slider.slides.filter(
                        (el, idx) => idx >= this.slider.activeIndex
                    ),
                    opacity: 1,
                    duration: 150,
                    easing: "easeInOutSine",
                });

                [
                    ...this.slider.slides.filter(
                        (el, idx) => idx >= this.slider.activeIndex
                    ),
                ].forEach((el) => {
                    el.classList.remove("pointer-events-none");
                });

                this.slider.on("slideChange", this.onSlideChange.bind(this));
            } else {
                if (this.slider.slides) {
                    this.slider.slides.forEach((el) => {
                        el.style.opacity = 1;
                    });
                }
                this.slider.off("slideChange", this.onSlideChange);
            }

            window.addEventListener("resize", () => {
                if (checkBreakpoint.up("lg")) {
                    this.$anime({
                        targets: this.slider.slides.filter(
                            (el, idx) => idx < this.slider.activeIndex
                        ),
                        opacity: 0,
                        duration: 150,
                        easing: "easeInOutSine",
                    });

                    [
                        ...this.slider.slides.filter(
                            (el, idx) => idx < this.slider.activeIndex
                        ),
                    ].forEach((el) => {
                        el.classList.add("pointer-events-none");
                    });

                    this.$anime({
                        targets: this.slider.slides.filter(
                            (el, idx) => idx >= this.slider.activeIndex
                        ),
                        opacity: 1,
                        duration: 150,
                        easing: "easeInOutSine",
                    });

                    [
                        ...this.slider.slides.filter(
                            (el, idx) => idx >= this.slider.activeIndex
                        ),
                    ].forEach((el) => {
                        el.classList.remove("pointer-events-none");
                    });

                    this.slider.on(
                        "slideChange",
                        this.onSlideChange.bind(this)
                    );
                } else {
                    if (this.slider.slides) {
                        this.slider.slides.forEach((el) => {
                            el.style.opacity = 1;
                        });
                        this.slider.slides.forEach((el) => {
                            el.classList.remove("pointer-events-none");
                        });
                    }
                    this.slider.off("slideChange", this.onSlideChange);
                }

                this.slider.update();
            });

            return;
        }

        if (this.slides) {
            if (this.slider.slides) {
                this.slider.slides.forEach((el) => {
                    el.style.opacity = 1;
                });
                this.slider.slides.forEach((el) => {
                    el.classList.remove("pointer-events-none");
                });
            }

            this.slider.off("slideChange", this.onSlideChange);
        }
    },

    methods: {
        ...mapActions([
            "ui/UPDATE_FULL_SLIDER_DATA",
            "ui/UPDATE_FULL_SLIDER_THUMB",
        ]),

        onSlideChange(swiper) {
            this.$anime({
                targets: swiper.slides.filter(
                    (el, idx) => idx < swiper.activeIndex
                ),
                opacity: 0,
                duration: 300,
                easing: "easeInOutSine",
            });

            [
                ...swiper.slides.filter((el, idx) => idx < swiper.activeIndex),
            ].forEach((el) => {
                el.classList.add("pointer-events-none");
            });

            this.$anime({
                targets: swiper.slides.filter(
                    (el, idx) => idx >= swiper.activeIndex
                ),
                opacity: 1,
                duration: 300,
                easing: "easeInOutSine",
            });

            [
                ...swiper.slides.filter((el, idx) => idx >= swiper.activeIndex),
            ].forEach((el) => {
                el.classList.remove("pointer-events-none");
            });
        },
    },
};
