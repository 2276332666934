var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{tag:"component",class:[
        'm-media',
        _vm.hasContent === true ? 'flex items-center gap-x-5 lg:gap-x-12' : '',
        _vm.className ? _vm.className : '' ]},[(
            _vm.image ||
            (_vm.isToggle === true && _vm.imagesToggle && _vm.imagesToggle.length > 0)
        )?_c('div',{class:[
            'relative',
            _vm.hasContent === true
                ? 'w-1/2 min-w-1/2 sm:w-1/3 sm:min-w-1/3'
                : '',
            _vm.classWrapperContainerExtra ]},[_c('div',{class:[
                _vm.classWrapper
                    ? _vm.classWrapper
                    : 'h-auto pt-media-padding relative w-auto',
                _vm.classWrapperExtra ]},[(_vm.ring === true)?_c('BowRing',{class:_vm.ringClassExtra,attrs:{"className":_vm.ringClass}}):_vm._e(),_vm._v(" "),(_vm.isToggle === true)?_c('div',{class:[
                    'absolute bg-white h-auto left-1/2 px-8 py-12 rounded-media',
                    '-translate-x-1/2 -translate-y-1/2 top-1/2 w-[85%]',
                    'sm:px-12 sm:py-24',
                    'lg:w-full xl:px-16 2xl:px-20 2xl:py-36' ]},[_c('BowHeading',{staticClass:"text-center text-primary text-2xl xl:text-3xl 2xl:text-4xl",attrs:{"weight":"font-bold"}},[_vm._v("\n                    "+_vm._s(_vm.$t("Planimetrie"))+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"mt-3 mx-auto relative w-full"},[_c('BowSlider',{attrs:{"autoplay":true,"breakpoints":{},"effect":'fade',"loop":true,"preventClicks":false,"slidesPerView":1,"spaceBetween":0,"thumb":false}},_vm._l((_vm.imagesToggle),function(i,idx){return _c('BowSlide',{key:idx,staticClass:"bg-white"},[_c('div',{staticClass:"pt-[100%] relative w-full"},[_c('div',{staticClass:"cursor-pointer",attrs:{"role":"link"},on:{"click":function($event){$event.preventDefault();return _vm.handleClickZoom(idx)}}},[_c('BowImage',{attrs:{"alt":i.alt,"classPicture":[
                                            'absolute block h-auto left-0 pt-[100%] top-0 w-full' ],"classImage":[
                                            'absolute h-full left-1/2 object-cover top-0 -translate-x-1/2 w-auto' ],"image":i.image,"radius":false,"size":"","sizes":_vm.sizes}})],1)]),_vm._v(" "),(i.title && i.title !== '')?_c('p',{staticClass:"text-center text-sm 2xl:text-base"},[_vm._v("\n                                "+_vm._s(i.title)+"\n                            ")]):_vm._e()])}),1)],1)],1):_c('BowImage',{class:_vm.classPictureExtra,attrs:{"alt":_vm.image.alt,"classPicture":[
                    _vm.classPicture
                        ? _vm.classPicture
                        : 'absolute block h-full left-0 top-0 w-full' ],"classImage":[
                    _vm.classImage ? _vm.classImage : 'h-full object-cover w-full' ],"classImageExtra":_vm.classImageExtra,"image":_vm.image,"size":"bow-thumb-media","sizes":_vm.sizes}})],1)]):_vm._e(),_vm._v(" "),(_vm.hasContent === true)?_c('div',{class:[
            _vm.classContent && _vm.classContent !== ''
                ? _vm.classContent
                : 'flex flex-col items-start justify-center',
            _vm.classContentExtra ]},[(_vm.title && _vm.title !== '')?_c('BowHeading',{staticClass:"text-primary text-2xl",attrs:{"weight":"font-bold"}},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]):_vm._e(),_vm._v(" "),_c('client-only',[(_vm.text && _vm.text !== '')?_c('BowText',{staticClass:"mt-4 text-lg lg:text-xl",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e()],1),_vm._v(" "),(_vm.link && _vm.link.length > 0)?_c('BowCallToAction',{staticClass:"flex justify-start mt-4 text-primary",attrs:{"cta":_vm.link,"componentType":"circle","theme":"secondary"}}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }