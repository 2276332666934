//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowMedia",
    props: {
        classContent: {
            default: null,
            type: [Array, String],
        },
        classContentExtra: {
            default: "",
            type: [Array, String],
        },
        classImage: {
            default: null,
            type: [Array, String],
        },
        classImageExtra: {
            default: "",
            type: [Array, String],
        },
        className: {
            default: null,
            type: [Array, String],
        },
        classPicture: {
            default: null,
            type: [Array, String],
        },
        classPictureExtra: {
            default: "",
            type: [Array, String],
        },
        classWrapper: {
            default: null,
            type: [Array, String],
        },
        classWrapperContainerExtra: {
            default: "",
            type: [Array, String],
        },
        classWrapperExtra: {
            default: "",
            type: [Array, String],
        },
        image: {
            // default: () => ({
            //   height: null,
            //   provider: 'wordpress',
            //   url: null,
            //   width: null
            // }),
            default: null,
            type: Object,
        },
        imagesToggle: {
            default: false,
            type: [Boolean, Array],
        },
        link: {
            default: null,
            type: Array,
        },
        ring: {
            default: true,
            type: Boolean,
        },
        ringClass: {
            default: null,
            type: [Array, String],
        },
        ringClassExtra: {
            default: "",
            type: [Array, String],
        },
        sizes: {
            default: "",
            type: String,
        },
        text: {
            default: null,
            type: String,
        },
        title: {
            default: null,
            type: String,
        },
    },

    data: () => ({
        toggleActive: 0,
    }),

    computed: {
        hasContent() {
            if (
                (this.title && this.title !== "") ||
                (this.text && this.text !== "") ||
                (this.link && this.link !== "")
            ) {
                return true;
            }

            return false;
        },

        isToggle() {
            if (this.imagesToggle && this.imagesToggle !== false) {
                if (
                    typeof this.imagesToggle !== "boolean" &&
                    this.imagesToggle.length > 0
                ) {
                    return true;
                }
            }

            return false;
        },
    },

    // mounted() {
    //   console.log(this.image);
    // },

    methods: {
        handleToggle(idx) {
            this.toggleActive = idx;
        },

        handleClickZoom(idx) {
            const image = this.imagesToggle[idx];

            const zoomedImage = document.createElement("div");
            zoomedImage.classList.add(
                "m-zoomed-image-box",
                "fixed",
                "flex",
                "h-screen",
                "items-center",
                "justify-center",
                "left-0",
                "top-0",
                "w-full",
                "z-[60]"
            );

            const closeButton = document.createElement("div");
            closeButton.classList.add(
                "absolute",
                "bg-white",
                "cursor-pointer",
                "duration-300",
                "flex-col",
                "flex",
                "h-11",
                "items-center",
                "justify-center",
                "right-6",
                "rounded-full",
                "top-6",
                "w-11",
                "z-10",
                "hover:scale-75"
            );
            closeButton.addEventListener("click", this.closeZoomedPicture);

            const icon = document.createElement("i");
            icon.classList.add(
                "bi",
                "bi-x-lg",
                "text-xl",
                "text-center",
                "text-secondary"
            );
            closeButton.appendChild(icon);

            const overlay = document.createElement("div");
            overlay.classList.add(
                "absolute",
                "bg-black/80",
                "h-full",
                "items-center",
                "justify-center",
                "left-0",
                "top-0",
                "w-full",
                "z-0"
            );
            overlay.addEventListener("click", this.closeZoomedPicture);

            const newImage = document.createElement("img");
            newImage.classList.add(
                "max-h-[90vh]",
                "object-cover",
                "w-auto",
                "z-[1]"
            );
            newImage.setAttribute("alt", image.alt);
            newImage.setAttribute("src", image.image.url);

            zoomedImage.appendChild(overlay);
            zoomedImage.appendChild(closeButton);
            zoomedImage.appendChild(newImage);

            document.body.appendChild(zoomedImage);
        },

        closeZoomedPicture() {
            const box = document.querySelector(".m-zoomed-image-box");
            box.classList.add("opacity-0", "pointer-events-none");

            box.remove();
        },
    },
};
