var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{tag:"component",class:[
        'm-slider',
        _vm.thumb === true ? 'm-slider--thumb' : '',
        'relative' ]},[(_vm.thumb === true)?_c('div',{ref:"slider-container-thumb",class:[
            'm-slider__thumb__container w-full swiper js-slider-thumb',
            'cursor-slider' ]},[_c('div',{class:['m-slider__thumb__wrapper swiper-wrapper w-full']},[_vm._t("default")],2)]):_c('div',{ref:"slider-container",class:[
            'm-slider__container w-full swiper js-slider',
            _vm.thumb === true && false
                ? [
                      'fixed top-0 left-0 w-full h-screen z-50',
                      _vm.showFullSlider === true
                          ? ''
                          : 'opacity-0 pointer-events-none' ]
                : '',
            _vm.fadeOutSlide === true ? 'lg:overflow-visible' : '' ]},[_c('div',{class:[
                'm-slider__wrapper swiper-wrapper w-full',
                _vm.pagination !== false ? 'pb-12' : '' ]},[_vm._t("default")],2)]),_vm._v(" "),(_vm.thumb === false && _vm.pagination !== false)?_c('div',{ref:"slider-pagination",staticClass:"swiper-pagination"}):_vm._e(),_vm._v(" "),(_vm.thumb === false && _vm.navigation !== false)?_c('div',{class:[
            'm-slider__navigation hidden',
            'lg:absolute lg:block lg:left-0 lg:top-0 lg:w-full',
            _vm.showFullSlider === true ? '' : 'opacity-0 pointer-events-none' ]},[_c('div',{ref:"slider-prev",class:[
                'swiper-button-prev',
                'bg-primary left-0 rounded-full text-white',
                'transform -translate-x-1/2 z-1' ]},[_c('BowIcons',{staticClass:"text-white",attrs:{"icon":"chevron-left"}})],1),_vm._v(" "),_c('div',{ref:"slider-next",class:[
                'swiper-button-next',
                'bg-primary rounded-full text-white',
                'transform translate-x-1/2 z-1' ]},[_c('BowIcons',{staticClass:"text-white",attrs:{"icon":"chevron-right"}})],1)]):_vm._e(),_vm._v(" "),(_vm.thumb === true && _vm.paginationThumb !== false)?_c('div',{ref:"slider-pagination-thumb",staticClass:"swiper-pagination"}):_vm._e(),_vm._v(" "),(_vm.thumb === true && _vm.navigationThumb !== false)?_c('div',{class:[
            'm-slider__navigation',
            'hidden lg:absolute lg:block lg:left-0 lg:top-0 lg:w-full' ]},[_c('div',{ref:"slider-prev",class:[
                'swiper-button-prev',
                'bg-primary left-0 rounded-full text-white',
                'transform -translate-x-1/2 z-1' ]},[_c('BowIcons',{staticClass:"text-white",attrs:{"icon":"chevron-left"}})],1),_vm._v(" "),_c('div',{ref:"slider-next",class:[
                'swiper-button-next',
                'bg-primary rounded-full text-white',
                'transform translate-x-1/2 z-1' ]},[_c('BowIcons',{staticClass:"text-white",attrs:{"icon":"chevron-right"}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }